import * as Sdk from '@wix/client-search-sdk';
import { cloneDeep } from 'lodash';

type Groups = Sdk.FederatedSuggestions['results'];

export const limitSuggestions = (
  groups: Groups,
  group: Groups[number],
  _: number,
  allGroups: Groups,
): Groups => {
  const newGroup = cloneDeep(group);
  newGroup.documents = newGroup.documents.slice(
    0,
    allGroups.length === 1 ? 6 : 3,
  );
  groups.push(newGroup);
  return groups;
};
