import {
  ClientSearchSDK,
  IErrorResponse,
  ISampleResponse,
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
  SearchDocumentType,
} from '@wix/client-search-sdk';
import { AppSettings } from '@wix/search-settings-client';
import { ControllerFlowAPI, Experiments } from '@wix/yoshi-flow-editor';

import { ISearchLocation } from '../../../../../lib/location';
import { SearchRequestBiLogger } from '../bi';
import { ISearchSample } from '../searchResultsControllerStore';
import { getSearchResponse } from './getSearchResponse';
import { getSamplesResponse } from './getSamplesResponse';
import { getDocumentIds } from './getDocumentIds';
import { getResponseTotals } from './getResponseTotals';
import { getDocumentTypes } from './getDocumentTypes';
import { SearchRequestStatus } from '../../types/types';
import { getPriceFacetResponse } from './getPriceFacetResponse';
import { getCollectionsFacetResponse } from './getCollectionsFacetResponse';
import { mergeFacets } from '../products';
import { SessionStore, SessionStoreKey } from '../../../../../lib/sessionStore';

export type SearchParams = {
  biLogger?: SearchRequestBiLogger;
  sessionStore: SessionStore;
  appSettings: AppSettings;
  environment: ControllerFlowAPI['environment'];
  experiments: Experiments;
  correlationId?: string;
  shouldShowProductFacets(documentType?: SearchDocumentType): boolean;
  withFacets(searchRequest: ISearchRequest): ISearchRequest;
  withOrdering(searchRequest: ISearchRequest): ISearchRequest;
  previousQuery?: string;
  previousTotals?: ISearchResponseTotals;
  previousVisibleDocumentTypes?: SearchDocumentType[];
  previousSearchRequestStatus: SearchRequestStatus;
  previousSearchRequest: ISearchRequest;
  previousSearchResponse: ISearchResponse;
  searchLocation: ISearchLocation;
  searchRequest: ISearchRequest;
  searchResultsAbsoluteUrl: string;
  searchSDK: ClientSearchSDK;
};

export const search = async (
  searchParams: SearchParams,
): Promise<{
  searchRequest: ISearchRequest;
  searchResponse: ISearchResponse | IErrorResponse;
  searchResponseTotals: ISearchResponseTotals;
  searchSamples: ISearchSample[];
  visibleDocumentTypes: SearchDocumentType[];
}> => {
  const {
    biLogger,
    sessionStore,
    appSettings,
    environment,
    experiments,
    correlationId,
    shouldShowProductFacets,
    withFacets,
    withOrdering,
    previousQuery,
    previousTotals,
    previousVisibleDocumentTypes,
    searchLocation,
    searchResultsAbsoluteUrl,
    searchSDK,
  } = searchParams;

  let { searchRequest } = searchParams;

  try {
    const shouldUpdateTotals =
      !previousTotals || previousQuery !== searchRequest.query;

    const shouldLoadSamples =
      !previousVisibleDocumentTypes ||
      !searchRequest.documentType ||
      searchRequest.documentType === SearchDocumentType.All ||
      shouldUpdateTotals;

    let searchSampleResponse: ISampleResponse = { results: [] };

    searchRequest.includeSeoHidden = appSettings.isSeoHiddenIncluded;

    if (shouldLoadSamples) {
      const sampleResponse = await getSamplesResponse({
        searchRequest,
        searchSDK,
        environment,
        correlationId,
      });

      if ('isError' in sampleResponse) {
        return {
          searchResponse: sampleResponse,
          searchRequest,
          visibleDocumentTypes: previousVisibleDocumentTypes ?? [],
          searchResponseTotals: previousTotals ?? {},
          searchSamples: [],
        };
      }

      searchSampleResponse = sampleResponse;
    }

    const visibleDocumentTypes =
      shouldUpdateTotals || !previousVisibleDocumentTypes
        ? getDocumentTypes(searchSampleResponse, appSettings, experiments)
        : previousVisibleDocumentTypes;

    const searchResponseTotals =
      shouldUpdateTotals || !previousVisibleDocumentTypes
        ? getResponseTotals(searchSampleResponse, visibleDocumentTypes)
        : previousTotals;

    // Requested document type is not visible (per display settings etc)
    if (
      !searchRequest.documentType ||
      (searchRequest.documentType &&
        !visibleDocumentTypes.includes(searchRequest.documentType))
    ) {
      searchRequest.documentType =
        visibleDocumentTypes[0] ?? SearchDocumentType.All;
    }

    const shouldShowSamples =
      !visibleDocumentTypes.length ||
      searchRequest.documentType === SearchDocumentType.All;

    searchRequest = withOrdering(searchRequest);

    const facetsEnabled = shouldShowProductFacets(searchRequest.documentType);

    if (facetsEnabled) {
      searchRequest = withFacets(searchRequest);
    }

    searchParams.searchRequest = searchRequest;

    const [
      searchResponse,
      priceFacetsResponse,
      collectionsFacetsResponse,
    ] = await Promise.all([
      getSearchResponse(
        searchRequest,
        shouldShowSamples,
        searchSDK,
        correlationId,
      ),
      facetsEnabled
        ? getPriceFacetResponse(searchParams, correlationId)
        : Promise.resolve(undefined),
      facetsEnabled
        ? getCollectionsFacetResponse(searchParams, correlationId)
        : Promise.resolve(undefined),
    ]);

    const commonErrorResponse = {
      searchRequest,
      visibleDocumentTypes,
      searchResponseTotals,
      searchSamples: [],
    };

    if ('isError' in searchResponse) {
      return { ...commonErrorResponse, searchResponse };
    }

    if (priceFacetsResponse && 'isError' in priceFacetsResponse) {
      return { ...commonErrorResponse, searchResponse: priceFacetsResponse };
    }

    if (collectionsFacetsResponse && 'isError' in collectionsFacetsResponse) {
      return {
        ...commonErrorResponse,
        searchResponse: collectionsFacetsResponse,
      };
    }

    if (facetsEnabled) {
      searchResponse.facets = mergeFacets(
        priceFacetsResponse || searchResponse,
        collectionsFacetsResponse || searchResponse,
      );
    }

    if (shouldShowSamples) {
      searchResponse.totalResults =
        searchResponseTotals[SearchDocumentType.All] || 0;
    }

    const searchSamples: ISearchSample[] = searchSampleResponse.results
      .map((searchSample) => ({
        ...searchSample,
        url: searchLocation.buildSearchResultsUrl(searchResultsAbsoluteUrl, {
          query: searchRequest.query,
          documentType: searchSample.documentType,
        }),
      }))
      .sort(
        (searchSampleA, searchSampleB) =>
          visibleDocumentTypes.indexOf(searchSampleA.documentType) -
          visibleDocumentTypes.indexOf(searchSampleB.documentType),
      );

    biLogger?.finished(
      searchRequest,
      searchResponse,
      searchResponseTotals,
      getDocumentIds({
        searchResponse,
        searchSamples,
        shouldShowSamples,
      }),
    );

    return {
      searchRequest,
      searchResponse,
      searchResponseTotals,
      searchSamples,
      visibleDocumentTypes,
    };
  } catch (error) {
    biLogger?.failed(searchRequest, error);
    throw error;
  } finally {
    sessionStore.remove(SessionStoreKey.BiSearchOrigin);
  }
};
