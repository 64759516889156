import { ClientSearchSDK } from '@wix/client-search-sdk';
import { createSettingsClient } from '@wix/search-settings-client';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { createSearchLocation } from './location';
import { Spec } from './specs';
import { reportError } from './errors';

export const extendConfig = (props: ControllerParams) => {
  const { flowAPI, controllerConfig } = props;
  const { wixCodeApi } = controllerConfig;
  const { environment, experiments, httpClient, errorMonitor } = flowAPI;
  const { isViewer, language } = environment;
  const siteBaseUrl = wixCodeApi.location.baseUrl;

  const settingsClient = createSettingsClient({
    httpClient,
    defaultImageResizeModeFit: experiments.enabled(
      Spec.DefaultImageResizeModeFit,
    ),
  });

  const getAppSettings = async () => {
    try {
      const appSettings = isViewer
        ? await settingsClient.getPublished()
        : await settingsClient.getSaved();

      return appSettings;
    } catch (error) {
      reportError(errorMonitor, error);
      return settingsClient.getDefault();
    }
  };

  const useOnlyQueryParams = experiments.enabled(Spec.UrlQueryParams);
  const searchLocation = createSearchLocation(wixCodeApi, useOnlyQueryParams);

  const searchSDK = new ClientSearchSDK({
    httpClient,
    siteBaseUrl,
    language,
    timeout: 25000,
  });

  return {
    ...controllerConfig,
    getAppSettings,
    searchLocation,
    searchSDK,
    flowAPI,
  };
};
