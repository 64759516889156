import { VisitorLogger, IPlatformAPI } from '@wix/yoshi-flow-editor';
import { SearchDocumentType } from '@wix/client-search-sdk';
import {
  searchBoxSearchSubmit,
  searchBoxSuggestionsRequestStarted,
  searchBoxSuggestionsRequestFinished,
  searchBoxSuggestionClick,
  searchBoxSuggestionShowAllClick,
  searchBoxFocused,
  searchBoxStartedWritingAQuery,
  searchBoxCleared,
  searchBoxAutoCompleteShown,
  searchBoxAutoCompleteShownSuggestionApproved,
} from '@wix/bi-logger-wix-search-platform/v2';

import { BiSearchOrigin, createBiCorrelationId } from '../../../../lib/bi';
import { SuggestionItems } from './types';
import { getSuggestionsStats } from './getSuggestionsStats';
import { SessionStore, SessionStoreKey } from '../../../../lib/sessionStore';

const correlationIdTimeoutInMilliseconds = 30 * 1000;

export type ISearchPlatformBiLogger = ReturnType<
  typeof createSearchPlatformBiLogger
>;

export function createSearchPlatformBiLogger(
  platformAPIs: IPlatformAPI,
  bi: VisitorLogger,
) {
  const sessionStore = new SessionStore(platformAPIs);

  let correlationIdLastUsedAt: number | undefined;
  let lastSuggestions: SuggestionItems | undefined;
  const shouldGenerateNewCorrelationId: () => boolean = () => {
    return (
      !correlationIdLastUsedAt ||
      Date.now() - correlationIdLastUsedAt > correlationIdTimeoutInMilliseconds
    );
  };

  const generateCorrelationIdIfNeeded = () => {
    if (shouldGenerateNewCorrelationId()) {
      const correlationId = createBiCorrelationId();
      sessionStore.set(SessionStoreKey.BiSuggestionsCorrelation, correlationId);
      sessionStore.set(SessionStoreKey.BiSearchCorrelation, correlationId);
    }
    correlationIdLastUsedAt = Date.now();
  };

  return {
    get searchCorrelationId() {
      generateCorrelationIdIfNeeded();
      return sessionStore.get(SessionStoreKey.BiSearchCorrelation);
    },
    get suggestionsCorrelationId() {
      generateCorrelationIdIfNeeded();
      return sessionStore.get(SessionStoreKey.BiSuggestionsCorrelation);
    },
    searchSubmit: (params: { isDemoContent: boolean; searchQuery: string }) => {
      generateCorrelationIdIfNeeded();

      sessionStore.set(
        SessionStoreKey.BiSearchOrigin,
        BiSearchOrigin.EditorSearchBar,
      );

      bi.report(
        searchBoxSearchSubmit({
          correlationId: sessionStore.get(SessionStoreKey.BiSearchCorrelation),
          isDemo: params.isDemoContent,
          target: params.searchQuery,
        }),
      );
    },

    resetCorrelationId: () => {
      correlationIdLastUsedAt = undefined;
    },

    searchBoxSuggestionsRequestStarted: (startParams: {
      searchQuery: string;
    }) => {
      generateCorrelationIdIfNeeded();
      const startTime = Date.now();

      const correlationId = sessionStore.get(
        SessionStoreKey.BiSuggestionsCorrelation,
      );

      const commonProps = {
        correlationId,
        target: startParams.searchQuery,
      };

      bi.report(searchBoxSuggestionsRequestStarted(commonProps));

      return (finishParams: {
        success: boolean;
        error?: string;
        suggestions: SuggestionItems;
      }) => {
        const loadingDuration = Date.now() - startTime;

        if (finishParams.success) {
          lastSuggestions = finishParams.suggestions;
          const {
            resultCount,
            resultsArray,
            documentIds,
          } = getSuggestionsStats(finishParams.suggestions);

          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          bi.report(
            searchBoxSuggestionsRequestFinished({
              ...commonProps,
              documentIds,
              loadingDuration,
              resultCount,
              resultsArray,
              success: true,
            }),
          );
        } else {
          lastSuggestions = undefined;
          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          bi.report(
            searchBoxSuggestionsRequestFinished({
              ...commonProps,
              error: finishParams.error,
              loadingDuration,
              success: false,
            }),
          );
        }
      };
    },

    searchBoxSuggestionClick: (params: {
      title: string;
      url: string;
      searchQuery: string;
      index: number;
      documentType: string;
      suggestions: SuggestionItems;
    }) => {
      const { resultsArray } = getSuggestionsStats(params.suggestions);
      const correlationId = sessionStore.get(
        SessionStoreKey.BiSuggestionsCorrelation,
      );

      const clickedSuggestion = params.suggestions.find(
        (s) => s.url === params.url,
      );

      // 99:243 searchBox.suggestResults.click
      // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/243?artifactId=com.wixpress.wix-search-platform
      bi.report(
        searchBoxSuggestionClick({
          correlationId,
          documentId: clickedSuggestion?.id,
          documentType: params.documentType,
          pageUrl: params.url,
          resultsArray,
          searchIndex: params.index,
          target: params.searchQuery,
          resultClicked: params.title,
        }),
      );
    },

    searchBoxSuggestionSearchAllClick: (params: { searchQuery: string }) => {
      sessionStore.set(
        SessionStoreKey.BiSearchOrigin,
        BiSearchOrigin.EditorSearchBar,
      );
      bi.report(
        searchBoxSuggestionShowAllClick({
          correlationId: sessionStore.get(
            SessionStoreKey.BiSuggestionsCorrelation,
          ),
          // NOTE: what to do if there is only one tab? (so no All tab)
          documentType: SearchDocumentType.All,
          target: params.searchQuery,
        }),
      );
    },

    searchBoxSuggestionShowAllClick: (params: {
      searchQuery: string;
      documentType: string;
    }) => {
      sessionStore.set(
        SessionStoreKey.BiSearchOrigin,
        BiSearchOrigin.EditorSearchBar,
      );
      bi.report(
        searchBoxSuggestionShowAllClick({
          correlationId: sessionStore.get(
            SessionStoreKey.BiSuggestionsCorrelation,
          ),
          documentType: params.documentType,
          target: params.searchQuery,
        }),
      );
    },
    searchBoxFocused: (params: { isDemo: boolean; isFullscreen: boolean }) => {
      bi.report(
        searchBoxFocused({
          isDemo: params.isDemo,
          isFullscreen: params.isFullscreen,
        }),
      );
    },
    searchBoxStartedWritingAQuery: (params: { isDemo: boolean }) => {
      bi.report(
        searchBoxStartedWritingAQuery({
          isDemo: params.isDemo,
        }),
      );
    },
    searchBoxCleared: (params: { isDemo: boolean; searchQuery: string }) => {
      const { resultsArray } = getSuggestionsStats(lastSuggestions || []);
      bi.report(
        searchBoxCleared({
          correlationId: sessionStore.get(
            SessionStoreKey.BiSuggestionsCorrelation,
          ),
          isDemo: params.isDemo,
          target: params.searchQuery,
          resultsArray,
        }),
      );
    },
    searchBoxAutoCompleteShown: (params: {
      autocompleteValue: string;
      searchQuery: string;
    }) => {
      generateCorrelationIdIfNeeded();
      bi.report(
        searchBoxAutoCompleteShown({
          correlationId: sessionStore.get(
            SessionStoreKey.BiSuggestionsCorrelation,
          ),
          target: params.searchQuery,
          suggestion: params.autocompleteValue,
        }),
      );
    },
    searchBoxAutoCompleteShownSuggestionApproved: (params: {
      previousQuery: string;
      searchQuery: string;
    }) => {
      generateCorrelationIdIfNeeded();
      bi.report(
        searchBoxAutoCompleteShownSuggestionApproved({
          correlationId: sessionStore.get(
            SessionStoreKey.BiSuggestionsCorrelation,
          ),
          target: params.searchQuery,
          previousTarget: params.previousQuery,
        }),
      );
    },
  };
}
