import {
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
  isCollectionsSelectionChanged,
  isMaxPriceSelected,
  isMinPriceSelected,
  shouldRequestFacet,
} from '../products';
import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';

export const getPriceFacetResponse = (
  searchParams: SearchParams,
  correlationId?: string,
) => {
  const { searchRequest, previousSearchResponse, searchSDK } = searchParams;

  const filter = convertProductFacetsRequestParamsToFilter(searchRequest);

  if (!isMinPriceSelected(filter) && !isMaxPriceSelected(filter)) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestFacet(searchParams, [isCollectionsSelectionChanged])) {
    return getSearchResponse(
      {
        ...searchRequest,
        ...convertProductFacetsFilterToRequestParams({
          collections: filter.collections,
        }),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      false,
      searchSDK,
      correlationId,
    );
  } else {
    return previousSearchResponse;
  }
};
