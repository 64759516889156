import { ISearchRequest, ClientSearchSDK } from '@wix/client-search-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

const LIMIT_MOBILE = 4;
const LIMIT_DESKTOP = 3;

export const getSamplesResponse = async ({
  searchRequest,
  searchSDK,
  environment,
  correlationId,
}: {
  searchRequest: ISearchRequest;
  searchSDK: ClientSearchSDK;
  environment: ControllerFlowAPI['environment'];
  correlationId?: string;
}) => {
  const limit =
    environment.isMobile && !environment.isEditorX
      ? LIMIT_MOBILE
      : LIMIT_DESKTOP;

  return searchSDK.getSample(
    {
      query: searchRequest.query,
      limit,
      includeSeoHidden: searchRequest.includeSeoHidden,
    },
    { correlationId },
  );
};
